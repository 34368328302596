import { urls } from "~/api/config";
import type {
  IGeohelperStore,
  IGeohelperStoreQuery,
} from "~/typings/api/geohelper";

export default () => {
  const fetchStore = (query: Partial<IGeohelperStoreQuery> = {}) => {
    const { $transport } = useNuxtApp();
    return $transport<IGeohelperStore>(urls.geohelper.store, { query });
  };

  return {
    fetchStore,
  };
};
