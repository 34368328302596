import type { IStoreSearchStore } from "~/typings/api/storeSearch";

export const useStoresStore = defineStore("stores", () => {
  const route = useRoute();
  const router = useRouter();
  const shopCodeQuery = computed(() => route.query?.shopCode || null);

  const storePromptCookie = useCookie(APP_COOKIE_STORAGE_KEYS.storePrompted, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });
  const _shopCodeCookie = useCookie(APP_COOKIE_STORAGE_KEYS.shopCode, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });
  const _shopCityCookie = useCookie(APP_COOKIE_STORAGE_KEYS.shopCity, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });
  const _shopIdCookie = useCookie(APP_COOKIE_STORAGE_KEYS.shopId, {
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
  });

  const previouslySelectedStore = ref<IStoreSearchStore | null>(null);
  const diffStore = ref<IStoreSearchStore | null>(null);
  const selectedStoreRef = ref<IStoreSearchStore | null>(null);
  const initialized = ref(false);
  const selectedStoreIdRef = ref<string>(
    String(_shopIdCookie.value || STORE_DEFAULT_STORE_ID),
  );
  const { fetchStore } = geohelperApi();
  const { fetchStoreByCode } = storeSearchApi();

  const selectedStore = computed({
    get: () => selectedStoreRef.value,
    set: (v: IStoreSearchStore | null) => {
      selectedStoreRef.value = v;
      _shopCodeCookie.value = v?.code ?? null;
      _shopCityCookie.value = v?.city ?? null;
    },
  });
  const selectedStoreId = computed({
    get: () => selectedStoreIdRef.value,
    set: (v: string | null) => {
      selectedStoreIdRef.value = v ?? "";
      _shopIdCookie.value = v;
    },
  });
  const requestShopId = computed(() =>
    String(selectedStoreId.value ?? STORE_DEFAULT_STORE_ID),
  );

  const requestShopCode = computed(() => {
    const src = initialized.value
      ? (diffStore.value ?? selectedStore.value ?? { ...STORE_DEFAULT_VALUE })
      : null;
    return String(
      src?.code ||
      shopCodeQuery.value ||
      _shopCodeCookie.value ||
      STORE_DEFAULT_STORE_CODE,
    );
  });

  const currentStore = computed(
    () =>
      previouslySelectedStore.value ??
      selectedStore.value ?? { ...STORE_DEFAULT_VALUE },
  );
  const currentStoreAddress = computed(() => currentStore.value.address ?? "");
  const currentStoreCity = computed(() => currentStore.value.city);
  const seoMetaCity = computed(
    () =>
      diffStore.value?.city || _shopCityCookie.value || currentStore.value.city,
  );

  const diffShopCode = computed(() => {
    const previousCode = _shopCodeCookie.value
      ? String(_shopCodeCookie.value)
      : STORE_DEFAULT_VALUE.code;
    return shopCodeQuery.value && String(shopCodeQuery.value) !== previousCode
      ? shopCodeQuery.value
      : null;
  });
  const isDiffShopCodeQuery = computed(
    () => !!(diffStore.value && diffShopCode.value),
  );

  const storePrompted = computed(() => storePromptCookie.value === "Y");
  const setStorePrompt = (v = "Y") => {
    storePromptCookie.value = v;
  };

  const fetchStoreByStoreCode = async (code: string | null) => {
    try {
      if (!code) return null;
      if (code === STORE_DEFAULT_STORE_CODE) return STORE_DEFAULT_VALUE;
      const data = await fetchStoreByCode(code);
      return data?.store ?? STORE_DEFAULT_VALUE;
    } catch (err) {
      logError("Неудачная попытка получить информацию о магазине по коду ", {
        code,
        err,
      });
      return null;
    }
  };
  const fetchStoreIdByStoreCode = async (code: string | null | undefined) => {
    try {
      if (!code) return null;
      if (code === STORE_DEFAULT_VALUE.code) return STORE_DEFAULT_STORE_ID;
      if (code === String(_shopCodeCookie.value) && _shopIdCookie.value)
        return String(_shopIdCookie.value);
      const data = await fetchStore({ code });
      return data?.id ?? STORE_DEFAULT_STORE_ID;
    } catch (err) {
      logError(
        "Неудачная попытка получить информацию о магазине по коду от geohelper API ",
        { code, err },
      );
      return null;
    }
  };
  const setSelectedStore = (store: IStoreSearchStore, manual = false) => {
    const code = store?.code;
    if (!code) throw new Error("Выбраный магазин не обладает полем code");
    selectedStore.value = store;
    diffStore.value = null;
    previouslySelectedStore.value = null;
    if (manual) storePromptCookie.value = "Y";
    if (
      import.meta.client &&
        shopCodeQuery.value &&
        String(shopCodeQuery.value) !== requestShopCode.value
    ) {
      router.replace({
        query: { ...route.query, shopCode: requestShopCode.value },
      });
    }
  };
  const submitSelectedStore = async (store: IStoreSearchStore) => {
    setSelectedStore(store, true);
    const id = await fetchStoreIdByStoreCode(store.code);
    if (id && id !== selectedStoreId.value) {
      selectedStoreId.value = id;
    }
    setStorePrompt("Y");
  };
  const setDefaultStore = () => {
    const { code } = STORE_DEFAULT_VALUE;
    selectedStore.value = STORE_DEFAULT_VALUE;
    selectedStoreId.value = STORE_DEFAULT_STORE_ID;
    diffStore.value = null;
    previouslySelectedStore.value = null;
    if (
      import.meta.client &&
        shopCodeQuery.value &&
        String(shopCodeQuery.value) !== code
    ) {
      router.replace({ query: { ...route.query, shopCode: code } });
    }
  };
  const initializeSelectedStore = async () => {
    if (initialized.value) return;
    if (
      import.meta.server &&
        (!shopCodeQuery.value ||
          String(shopCodeQuery.value) === String(_shopCodeCookie.value))
    ) {
      return;
    }
    try {
      const previouslySetCode = _shopCodeCookie.value
        ? String(_shopCodeCookie.value)
        : STORE_DEFAULT_VALUE.code;
      const queryCode =
        !shopCodeQuery.value || shopCodeQuery.value === previouslySetCode
          ? null
          : (shopCodeQuery.value as string);
      const [previouslySetStore, queryStore, queryStoreId] = await Promise.all([
        fetchStoreByStoreCode(previouslySetCode),
        fetchStoreByStoreCode(queryCode),
        fetchStoreIdByStoreCode(queryCode),
      ]);
      if (
        queryStore &&
        (!previouslySetStore ||
          previouslySetStore.code === STORE_DEFAULT_VALUE.code)
      ) {
        // Вариант 1. Не найден предыдущий магазин или предыдущимй магазин - это магазин по умолчанию и найден магазин из адресной строки
        setSelectedStore(queryStore);
        if (queryStoreId && queryStoreId !== selectedStoreId.value) {
          selectedStoreId.value = queryStoreId;
        }
      } else if (previouslySetStore && !queryStore) {
        // Вариант 2. Не найден магазин из адресной строки, но найден предыдущий магазин
        setSelectedStore(previouslySetStore);
      } else if (!previouslySetStore && !queryStore) {
        // Вариант 3. Не найдены оба магазина
        setDefaultStore();
      } else if (previouslySetStore && queryStore) {
        // Вариант 4. Найдены оба магазина. Выставлена кука не является значением по умолчанию. Требуется подтверждение пользователя о желании сменить магазин
        // previouslySetStore отображается в хэдере страницы, queryStore должен быть предложен в качестве замены. Для этого используется diffStore.
        // Изначальная загрузка данных происходит по queryStore
        previouslySelectedStore.value = previouslySetStore;
        diffStore.value = queryStore;
        if (queryStoreId && queryStoreId !== selectedStoreId.value) {
          selectedStoreId.value = queryStoreId;
        }
      } else {
        setDefaultStore();
      }
    } catch (err) {
      logError("Ошибка инициализации хранилища магазинов: ", { err });
      setDefaultStore();
    } finally {
      initialized.value = true;
    }
  };

  return {
    initialized,
    selectedStore,
    previouslySelectedStore,
    diffStore,
    diffShopCode,
    isDiffShopCodeQuery,
    selectedStoreRef,
    selectedStoreIdRef,

    requestShopCode,
    requestShopId,

    currentStore,
    currentStoreAddress,
    currentStoreCity,
    seoMetaCity,
    storePrompted,

    fetchStoreByStoreCode,
    fetchStoreIdByStoreCode,
    setSelectedStore,
    submitSelectedStore,
    setDefaultStore,
    initializeSelectedStore,
    setStorePrompt,
  };
});
